import CanvasJSChart, {CanvasJS} from '@/assets/js/CanvasJSVueComponent.vue'
import moment from 'moment'
export default {
  components : { CanvasJSChart },
  data() {
    return {
      options: {
        animationEnabled: true,
        exportEnabled: false,
        backgroundColor: "transparent",
        zoomType: "xy",
        

        axisY: {
          labelFormatter: function(e) {
            return (e.value)
          },
          lineThickness: 0,
          labelFontColor: "rgba(255, 255, 255, 0.2)",
          // interval: 50,
          prefix: "$",
          gridColor: "rgba(255, 255, 255, 0.1)",
          margin: 20,
          tickLength: 0,
        },
        axisX: {
          lineThickness: 0,
          labelFontColor: "rgba(255, 255, 255, 0.2)",
          interval: 1,
          margin: 20,
          labelPlacement:"inside",
          tickLength: 0,
          xValueType: "dateTime",
        },
        toolTip: {
          shared: false,
          contentFormatter: function(e) {
            return "<span style='color:" + e.entries[0].dataSeries.color + ";'>" + e.entries[0].dataPoint.x + "</span>: "+ "$" + CanvasJS.formatNumber(e.entries[0].dataPoint.y, "#.#0");
          },
          backgroundColor: "transparent",
          fontColor: "#ffffff",
        },
        data: [
          {
            type: "splineArea",
            fillOpacity: "0.1", 
            color: "#FF9501",
            markerSize: 1,
            lineColor: "#FF9501",
            lineThickness: 1.2,
            dataPoints: []
          },
          {
            type: "splineArea",
            fillOpacity: "0.1", 
            color: "#C68DFF",
            markerSize: 1,
            lineColor: "#C68DFF",
            lineThickness: 1.2,
            dataPoints: [
              {x: new Date(2023, 5, 1) , y: 0},
              {x: new Date(2023, 4, 1) , y: 20},
              {x: new Date(2023, 3, 1) , y: 40},
              {x: new Date(2023, 2, 1) , y: 100},
              {x: new Date(2023, 1, 1) , y: 50},
              {x: new Date(2022, 12, 1) , y: 70},
            ]
          },
        ],
      },
      styleOptions: {
        width: "100%",
        height: "30vh",
      },
      lastMonths: [],
      lastMonthsSec: [],
      allDays: [],
      allDaysSec: [],
      year: [
        {x: 1},
        {x: 2},
        {x: 3},
        {x: 4},
        {x: 5},
        {x: 6},
        {x: 7},
        {x: 8},
        {x: 9},
        {x: 10},
        {x: 11},
        {x: 12},
      ],
      yearSec: [
        {x: 1},
        {x: 2},
        {x: 3},
        {x: 4},
        {x: 5},
        {x: 6},
        {x: 7},
        {x: 8},
        {x: 9},
        {x: 10},
        {x: 11},
        {x: 12},
      ],
    }
  },
  methods: {
    hundleSelectDate(item) {
      this.selectedDate = item
      let chart = new CanvasJS.Chart("chartContainer", {
        ...this.options,
      })
      switch(item) {
          case '1m':
            this.options.data[0].dataPoints = this.allDays
            this.options.data[1].dataPoints = this.allDaysSec
            chart.options['axisX'].reversed = false
            chart.render(); 
            break;
          case '5m':
            chart.options.data[0].dataPoints = this.lastMonths
            chart.options.data[1].dataPoints = this.lastMonthsSec
            chart.render();
            break;
          case '1y':
            this.options.data[0].dataPoints = this.year
            this.options.data[1].dataPoints = this.yearSec
            chart.options['axisX'].reversed = false
            chart.render();
            break;
      }
    },
  },
  mounted() {
    const monthOfDays = moment().daysInMonth()
    for (let idx = 1; idx < monthOfDays +1 ; idx++) {
      this.allDays.push({x: +idx, y: Math.floor(Math.random() * (120 - 5) + 5)});
      this.allDaysSec.push({x: +idx, y: Math.floor(Math.random() * (120 - 5) + 5)});
    }
    this.options.data[0].dataPoints = this.allDays
    this.options.data[1].dataPoints = this.allDaysSec

    for (let i = 0; i < 5; i++) {
      const month = moment().subtract(i, 'month').format('M');
      this.lastMonths.push({x: +month, y: Math.floor(Math.random() * (100 - 5) + 5)});
      this.lastMonthsSec.push({x: +month, y: Math.floor(Math.random() * (100 - 5) + 5)});
    }
// random year
    for (let index = 0; index < 12; index++) {
      this.year[index].y = Math.floor(Math.random() * (120 - 5) + 5)
      this.yearSec[index].y = Math.floor(Math.random() * (120 - 5) + 5)
    }
    
    if (window.innerHeight > 850) {
      this.styleOptions.height = "38vh"
    }
    if (window.innerWidth <= 768) {
      this.options.axisX.interval = 3
    }
  },
}