<template>
  <div class="main__columnNft" v-if="true">
    <div class="main__columnNft-passive">
      <div class="main__columnNft-passive_title">
          You have passive income
      </div>
      <div class="totals__sum">
          $1,200<span>.12</span>
      </div>
      <p class="main__columnNft-passive_description">
          This is your passive income and it is growing every day, upgrade your asset to increase the passive
      </p>
      <div class="btn-group">
          <span>You can transfer them to your account.</span>
          <button class="button button-lg orange">
              Withdraw
          </button>
      </div>
    </div>
    <div class="main__columnNft-activity">
      <div class="title">
        Recent activity
      </div>
      <div class="column__activity-items">
        <div class="column__activity-item">
          <div class="column__activity-item_name">
            Creating Just NFT
          </div>
          <div class="column__activity-item_date">
            8 October; 18:32
          </div>
        </div>
        <div class="column__activity-item">
          <div class="column__activity-item_name">
            New Just NFT detected
          </div>
          <div class="column__activity-item_date">
            8 October; 18:32
          </div>
        </div>
        <div class="column__activity-item">
          <div class="column__activity-item_tags">
            <div class="tag">
              Manager
            </div>
          </div>
          <div class="column__activity-item_name">
            Creating Just NFT
          </div>
          <div class="column__activity-item_date">
            8 October; 18:32
          </div>
        </div>
        <div class="column__activity-item">
          <div class="column__activity-item_tags">
            <div class="tag">
              Manager
            </div>
          </div>
          <div class="column__activity-item_name">
            NFT-R level 3 detected
          </div>
          <div class="column__activity-item_details">
            <div class="detail__merge">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="22" height="22" rx="11" fill="#F8956C" />
                <path d="M16 12L8 12M16 12L12.8 9M16 12L12.8 15" stroke="black" stroke-width="1.75"
                  stroke-linecap="square" stroke-linejoin="bevel" />
                <rect x="1" y="1" width="22" height="22" rx="11" stroke="#1A1A1A" stroke-width="2" />
              </svg>
              <p>
                Merged NFT level
                <span>20xf3a3d1b89a70e...2911ecb4a1299117f</span>
              </p>
            </div>
            <div class="detail__merge">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="22" height="22" rx="11" fill="#F8956C" />
                <path d="M16 12L8 12M16 12L12.8 9M16 12L12.8 15" stroke="black" stroke-width="1.75"
                  stroke-linecap="square" stroke-linejoin="bevel" />
                <rect x="1" y="1" width="22" height="22" rx="11" stroke="#1A1A1A" stroke-width="2" />
              </svg>
              <p>
                Merged NFT level
                <span>20xf3a3d1b89a70e...2911ecb4a1299117f</span>
              </p>
            </div>
            <div class="detail__merge">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="22" height="22" rx="11" fill="#F8956C" />
                <path d="M16 12L8 12M16 12L12.8 9M16 12L12.8 15" stroke="black" stroke-width="1.75"
                  stroke-linecap="square" stroke-linejoin="bevel" />
                <rect x="1" y="1" width="22" height="22" rx="11" stroke="#1A1A1A" stroke-width="2" />
              </svg>
              <p>
                Merged NFT level
                <span>20xf3a3d1b89a70e...2911ecb4a1299117f</span>
              </p>
            </div>
          </div>
          <div class="column__activity-item_date">
            8 October; 18:32
          </div>
        </div>
        <div class="column__activity-item">
          <div class="column__activity-item_tags">
            <div class="tag">
              Manager
            </div>
          </div>
          <div class="column__activity-item_name">
            Pumping Just NFT to progress 2880
          </div>
          <div class="column__activity-item_details">
            <div class="detail__merge">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="22" height="22" rx="11" fill="#808080" />
                <path d="M8 12H16M8 12L11.2 15M8 12L11.2 9" stroke="black" stroke-width="1.75" stroke-linecap="square"
                  stroke-linejoin="bevel" />
                <rect x="1" y="1" width="22" height="22" rx="11" stroke="#1A1A1A" stroke-width="2" />
              </svg>
              <p style="font-weight: 600;font-size: 16px;line-height: 24px;color: #FFFFFF;">
                - 390 SFC
              </p>
            </div>
          </div>
          <div class="column__activity-item_date">
            8 October; 18:32
          </div>
        </div>
        <div class="column__activity-item">
          <div class="column__activity-item_tags">
            <div class="tag">
              Manager
            </div>
          </div>
          <div class="column__activity-item_name">
            Crediting ROYALTY bonus
          </div>
          <div class="column__activity-item_details">
            <div class="detail__merge">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="22" height="22" rx="11" fill="#F8956C" />
                <path d="M16 12L8 12M16 12L12.8 9M16 12L12.8 15" stroke="black" stroke-width="1.75"
                  stroke-linecap="square" stroke-linejoin="bevel" />
                <rect x="1" y="1" width="22" height="22" rx="11" stroke="#1A1A1A" stroke-width="2" />
              </svg>
              <p style="font-weight: 600;font-size: 16px;line-height: 24px;color: #00A455;">
                + 850 DAI
              </p>
            </div>
          </div>
          <div class="column__activity-item_date">
            8 October; 18:32
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
.main__columnNft {
  width: 100%;
  margin-bottom: 45px;

  &-passive {
    margin-bottom: var(--indent-8);
    padding: var(--indent-32);
    background: var(--color-black-800);
    border-radius: var(--indent-8);

    &_title {
      font-weight: var(--font-600);
      font-size: var(--font-16);
      color: var(--color-orange-200);
    }

    .totals__sum {
      margin: var(--indent-8) 0 var(--indent-24);
      font-size: var(--font-32);
      span {
        color: var(--color-black-500);
      }
    }

    &_description {
      font-size: var(--font-14);
      color: var(--color-black-500);
    }

    .btn-group {
      display: flex;
      flex-direction: column;
      margin-top: 14%;

      span {
        font-size: var(--font-12);
        color: var(--color-black-500);
        text-align: center;
      }

      button {
        margin-top: 12px;
        background-color: var(--color-orange-900);
        padding: 10px;
        border-radius: 10px;
        transition: .3s;
        &:hover {
          background-color: var(--color-orange-800);
        }
      }

      @media (max-width: 1200px) {
        margin-top: var(--indent-52);
      }
    }
  }

  &-activity {
    background: #1a1a1a;
    border-radius: 8px;
    .title {
      display: flex;
      flex-direction: column;
      padding: var(--indent-24);
      background: var(--color-white-3);
      font-size: var(--font-22);
      line-height: var(--line-32);
      font-weight: var(--font-500);
      color: var(--color-black-50);
      border-radius: var(--indent-8) var(--indent-8) 0 0;
      @media (max-width: 1250px) {
        border-radius: 0;
      }

      span {
        font-weight: var(--font-400);
        font-size:  (--font-16);
        color: var(--color-black-500);
      }
    }

    .column__activity-items {
      height: 49.2vh;
      overflow-y: scroll;

      .column__activity-item {
        padding: 22px;

        &_tags {
          display: flex;
          margin-bottom: 6px;

          .tag {
            padding: 2px 8px;
            background: rgba(255, 255, 255, 0.1);
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.3);
            border-radius: 4px;

            &+.tag {
              margin-left: 4px;
            }
          }
        }

        &_name {
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #e6e6e6;

          @media (max-width: 560px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        &_details {
          margin-bottom: 8px;
          padding: 8px;
          background: rgba(255, 255, 255, 0.03);

          .detail__merge {
            display: grid;
            grid-template-columns: 24px auto;
            grid-gap: 8px;
            align-items: center;

            p {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              line-height: 20px;
              color: #e6e6e6;

              span {
                font-size: 12px;
                line-height: 18px;
                color: #666666;
              }
            }

            &+.detail__merge {
              margin-top: 8px;
            }
          }
        }

        &_date {
          margin-top: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #666666;

          @media (max-width: 560px) {
            margin-top: 4px;
            font-size: 12px;
            line-height: 18px;
          }
        }

        &+.column__activity-item {
          border-top: 1px solid #333333;
        }

        @media (max-width: 560px) {
          padding: 16px;
        }
      }

      @media (max-width: 992px) {
        height: auto;
        padding: 0;
      }
    }
  }
}
</style>