<template>
  <div class="Nft">
    <router-link :to="{ name: 'nfts' }" class="header-back">
      <svg-vue icon="back-arrow"/>
      <span class="header-back-title">{{ $t("nfts.backToNFTList") }}</span>
    </router-link>

    <UiLoader v-if="isNftLoading" theme="block" :loading="true"/>

        <div v-else-if="!!nft" class="main column-right">
            <div class="main__nft">
                <div class="main__nft-title">
                    <p class="name">
                        <span>{{ justNftName }}</span>
                        Royalty NFT
                    </p>
                    <p class="exp" v-if="isJustNft">
                        {{ justNftEnergy }} / {{ justNftEnergyMaxValue }}
                    </p>
                    <div class="progressbar" :style="{ width: justNftEnergyFill + '%' }" v-if="isJustNft"></div>
                </div>
                <div class="main__nft-item-container">
                    <div class="upgrateVersion" v-if="isUpgrateVersion">
                        <span>Version {{nftVersion}}</span>
                        <UiButton v-if="nftVersion === 1" @click="upgradeNftVersion" :text="'Go to version 2'" type="primary"/>
                    </div>
<!--                    Refresh metadata-->
                    <div class="refresh-metadata" v-if="isJustNft" @click="refreshMetadata">
                      <svg-vue icon="refresh-circle" :class="{'spin': isRefreshingMetadata}" />
                    </div>
                    <div class="main__nft-item">
                        <img :src="nft.nftMetadata.image" alt="nft image" />
<!--                        <div class="main__nft-item_address">-->
<!--                            0xbv8...D8735-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
            <div class="main__content">
                <div class="nft__upgrade" v-if="nft.owner === getAccount.toLowerCase() 
                    && contractAddress !== getLastNftAddress && nftVersion === 2">
                    <div class="title">
                        {{ $t("nfts.upgradeNFT") }}
                        <ul id="stats-tab" class="tabs">
                            <li :class="{ active: activeUpgradeTab === 'merge' }">
                                <a @click="activeUpgradeTab = 'merge'">
                                    {{ $t("nfts.byMerge") }}
                                </a>
                            </li>
                            <li :class="{ active: activeUpgradeTab === 'currency' }" v-if="isJustNft">
                                <a @click="activeUpgradeTab = 'currency'">
                                    {{ $t("nfts.byCurrency") }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div id="merge" class="tab" v-if="activeUpgradeTab === 'merge'">
                        <div class="upgrade__merge">
                            <div class="merge__add">
                                <div class="btn-add">
                                    <img class="btn-add-image" :src="nft.nftMetadata.image" alt="nft image" />
                                </div>
                                <button type="button" class="btn-add" @click="showModal" v-for="n in 2" :key="n">
                                    <template v-if="mergeForm[n] !== undefined">
                                        <img class="btn-add-image" :src="getMergeNftById(mergeForm[n]).nftMetadata.image" alt="nft image" />
                                    </template>
                                    <template v-else>
                                        <span>
                                            <svg-vue icon="plus" class="w-5 h-5"/>
                                            {{ $t("nfts.addNft") }}
                                        </span>
                                    </template>
                                </button>
                            </div>
                            <div class="merge__submit">
                                <div>
                                    {{ $t("nfts.mergeNote") }}
                                </div>
                                <button
                                    :disabled="!canMerge"
                                    type="button"
                                    class="btn-upgrade"
                                    :class="{ disabled: !canMerge }"
                                    @click="merge"
                                >
                                    {{ $t("nfts.merge") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="currency" class="tab" v-if="activeUpgradeTab === 'currency'">
                        <div class="upgrade__currency">
                            <div class="upgrade__currency-item">
                                <div class="token">
                                    <div class="token__img">
                                        <svg-vue icon="sfc" />
                                    </div>
                                    <div class="token__name">
                                        SFC {{ mySfcNewBalance }}
                                        <p><span>1 SFC = 1 energy</span></p>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    v-model="upgradeBySFCForm.sfcAmount"
                                    class="amount"
                                    name="amount"
                                    placeholder="Amount"
                                />
                                <button class="btn-upgrade" @click="lvlUpJustNFTBySFC('SFC')" 
                                  :class="{ disabled: !upgradeBySFCForm.sfcAmount }" 
                                  :disabled="!upgradeBySFCForm.sfcAmount"
                                >
                                    {{ $t("nfts.upgrade") }}
                                </button>
                            </div>
                            <div class="upgrade__currency-item">
                                <div class="token">
                                    <div class="token__img">
                                        <svg-vue icon="sfc" />
                                    </div>
                                    <div class="token__name">
                                        SFC2 {{ mySfc2Balance }}
                                        <p><span>1 SFC2 = 0.5 energy</span></p>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    v-model="upgradeBySFCForm.sfc2Amount"
                                    class="amount"
                                    name="amount"
                                    placeholder="Amount"
                                />
                                <button class="btn-upgrade" @click="lvlUpJustNFTBySFC('SFCNEW')" 
                                  :class="{ disabled: !upgradeBySFCForm.sfc2Amount }" 
                                  :disabled="!upgradeBySFCForm.sfc2Amount"
                                >
                                    {{ $t("nfts.upgrade") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nft__statistic">
                    <div class="title">
                        {{ $t("nfts.statistics") }}
                    </div>
                    <div class="nft__statistic-chart">
                        {{ $t("nfts.comingSoon") }}
                        <span>{{ $t("nfts.statisticsNote") }}</span>
                    </div>
                </div>
                <div class="statistics" v-if="false">    
                  <div class="title">
                      Statistic
                      <div class="sort__wrapper">
                          <!-- <ul id="stats-tab" class="tabs">
                              <li :class="statistics === 'revenue' && 'active'">
                                  <a @click.prevent.stop="statistics = 'revenue'">
                                      Revenue
                                  </a>
                              </li>
                              <li :class="statistics === 'referralActivity' && 'active'">
                                  <a @click.prevent.stop="statistics = 'referralActivity'">
                                      Referral activity
                                  </a>
                              </li>
                          </ul> -->

                          <ul class="sort">
                              <li 
                                  v-for="item in filterByDate" 
                                  :key="item" 
                                  :class="selectedDate === item && 'active'"
                                  @click.stop="hundleSelectDate(item)"
                              >
                                  <p>{{ item }}</p>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div id="revenue" class="tab active">
                      <div class="tab__content">
                          <div class="totals">
                              <div class="totals__item">
                                  <div class="totals__item__title">Earnings</div>
                                  <div class="totals__sum">
                                      <p class="earning">40.000</p>
                                      <div class="totals__percentage">
                                          <span>+15%</span>  
                                          <BaseIcon
                                              name="persentageArrow"
                                              verAlign
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div class="totals__item">
                                  <div class="totals__item__title">Spending</div>
                                  <div class="totals__sum">
                                      <p class="spending">200<span>.85</span></p>
                                      <div class="totals__percentage">
                                          <span>+15%</span>  
                                          <BaseIcon
                                              name="persentageArrow"
                                              verAlign
                                          />
                                      </div>
                                  </div>
                              </div>
                              <!-- <div class="totals__item" v-show="statistics === 'referralActivity'">
                                  <div class="totals__title">Referrals</div>
                                  <div class="totals__sum">
                                      <p>1,280</p>
                                      <div class="totals__percentage">
                                          <span>+15%</span>  
                                          <BaseIcon
                                              name="persentageArrow"
                                              verAlign
                                          />
                                      </div>
                                  </div>
                              </div> -->
                          </div>
                      </div>
                      <div class="chartDeleteText">
                        <CanvasJSChart :options="options" :styles="styleOptions" id="chartContainer" key="canvasJS"/>
                      </div>
                  </div>
              </div>
            </div>
            <RecentActivity v-if="false"/>
        </div>

    <UiModal
        v-if="nft"
        :visible="isModalShow"
        modifier="wide"
        title="NFT upgrade by merge"
        @closeModal="closeModal"
    >
      <div class="subtitle">Select two NFTs to merge it</div>
      <UiLoader v-if="isNftListLoading" theme="block" :loading="true"/>
      <div v-else-if="nftListToMerge.length > 0" class="modal__body">
        <div class="modal__body-item" v-for="item of nftListToMerge" :key="item.contractAddress + '-' + item.tokenId">
          <input
              :id="'nft-' + item.tokenId"
              type="checkbox"
              name="nft"
              hidden=""
              :value="item.tokenId"
              v-model="mergeForm"
              checked=""
              :disabled="item.tokenId === nft.tokenId"
          />
          <label :for="'nft-' + item.tokenId">
            <img :src="item.nftMetadata.image" alt="nft image"/>
            <p>{{ item.contractAddress | shortAccount }}</p>
            <div class="checked__number" v-if="mergeForm.includes(item.tokenId)">1</div>
          </label>
        </div>

        <!--Selected < 2-->
        <div class="modal__body-count">
          <p>
            Selected 1/2
          </p>
        </div>

        <!--Selected 2-->
        <div class="modal__body-count">
          <button class="modal__body-merge" :class="{disabled: !canMerge}" :disabled="!canMerge" @click="merge">
            {{ $t("nfts.merge") }}
            <svg
                style="margin-left: 8px;"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M15 10L5 10M15 10L11 6.25M15 10L11 13.75"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="bevel"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="modal__body_empty" v-else>
        {{ $t("nfts.notNftsToMerge") }}
      </div>
    </UiModal>
  </div>
</template>

<script>
import {utils} from "ethers"
import nftsApi from "@/api/nftsApi"
import {mapActions, mapGetters} from "vuex"
import pageReadyByConnect from "@/mixin/pageReadyByConnect"
import SvgVue from "@/components/UI/SvgVue.vue"
import RecentActivity from "./NftContent/RecentActivity.vue"
import BaseIcon from '@/components/UI/BaseIcon.vue'
import diagramNft from '@/mixin/diagramNft.js'

export default {
    name: "Nft",
    components: { 
        SvgVue, 
        RecentActivity, 
        BaseIcon,
    },
    mixins: [
        pageReadyByConnect,
        diagramNft
        ],
        //dont use as name options. this name reserved
    data() {
        return {
            isModalShow: false,
            // nftLastContract: process.env.VUE_APP_CRYPTO_CONTRACT_NFT_7,
            activeUpgradeTab: "merge",
            isNftLoading: false,
            isNftListLoading: true,
            isRefreshingMetadata: false,
            // justNftContractAddress: null,
            contractAddress: null,
            tokenId: null,
            isJustNft: false,
            nftVersion: null,
            nft: null,
            nftList: [],
            mergeForm: [], // list of tokens, should be less or equal than three
            intervalTimers: [],
            upgradeBySFCForm: {
                sfcAmount: null,
                sfc2Amount: null,
            },
            isUpgrateVersion: true,
            filterByDate: ['1m', '5m', '1y'],
            selectedDate: '1m'
            // statistics: 'revenue',
        }
    },
    async mounted() {
        this.contractAddress = this.$route.params.contractAddress
        this.tokenId = this.$route.params.tokenId
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    computed: {
      ...mapGetters("crypto", ["getAccount", "getBalance", "getNftLvl", "getRoyaltyNFTVersion", "isAddressJustNFT", "getLastNftAddress"]),
        mySfcNewBalance() {
            return this.getBalance.sfcNew
        },
        mySfc2Balance() {
            return this.getBalance.sfc2
        },
        canMerge() {
            return this.mergeForm.length === 3
        },
        // isJustNft() {
        //     return this.contractAddress === this.justNftContractAddress
        // },
        justNftEnergy() {
          const val = this.nft.nftMetadata.attributes.find((x) => x.traitType === "Energy").value;
          if (this.nftVersion === 1) {
            return utils.formatEther(val);
          }
          return val;
        },
        justNftEnergyMaxValue() {
          const val = this.nft.nftMetadata.attributes.find((x) => x.traitType === "Energy").maxValue
          if (this.nftVersion === 1) {
            return utils.formatEther(val);
          }
          return val;
        },
        justNftEnergyFill() {
            return this.justNftEnergy / this.justNftEnergyMaxValue * 100
        },
        nftListToMerge() {
            return this.nftList.filter(x => x.contractAddress === this.contractAddress && x.tokenId != this.tokenId)
        },
        justNftName() {
            const name = this.nft.nftMetadata.name
            let test = name
            test = test.split(' ')[1].split('')
            let endChar = test.pop()
            if (+endChar === 0) {
                let text = `Just ${test.join('')}`
                let justText = name.split(' ')
                justText.splice(1, 1, text)
                justText.splice(0, 1)
                return justText.join(' ')
            } else {
                let justText = name.split(' ')
                justText.splice(0,1)
                justText.splice(0,1)
                justText.unshift(`${endChar} level`)
                return justText.join(' ')
            }
        }
    },
    methods: {
      ...mapActions("crypto", ["levelUpJustNFTBySFC", "mergeNfts", "migrateRoyaltyNFT"]),
        showModal() {
            this.isModalShow = true
        },
        closeModal() {
            this.isModalShow = false
        },
      async pageReady() {
        this.isJustNft = this.isAddressJustNFT(this.contractAddress);
        this.nftVersion = this.getRoyaltyNFTVersion(this.contractAddress);
        console.log('this.isJustNft', this.isJustNft)
        await this.loadNft()
        if (this.isJustNft) {
          const intervalId = setInterval(() => {
            this.loadNft(false)
          }, 5000)
          this.intervalTimers.push(intervalId)
        }
        await this.loadNftList()
      },
      async loadNft(withLoading = true) {
          if (withLoading) {
              this.isNftLoading = true
          }

      await nftsApi
        .nft(this.$route.params.contractAddress, this.$route.params.tokenId)
        .then(({data}) => {
          this.nft = data
          this.mergeForm = [this.nft.tokenId]
        })
        .catch((err) => {
          console.log("err", err)
          this.$router.push({name: "nfts"})
        })
        .finally(() => {
          if (withLoading) {
            this.isNftLoading = false
          }
        })
    },
    async loadNftList() {
      this.isNftListLoading = true
      await nftsApi
        .nfts({
          owner: this.getAccount
        })
        .then(({data}) => {
          this.nftList = data
          console.log(data)
        })
        .catch(async (err) => {
          console.log("err", err)
        })
        .finally(() => {
          this.isNftListLoading = false
        })
    },
    async merge() {
      const [err, responce] = await this.mergeNfts({
        level: this.getNftLvl(this.contractAddress),
        ids: this.mergeForm,
        onBlockchainPending: () => {
          this.$store.commit("loader/showProcessingModal", {
            isShown: true,
            text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                                ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
          })
        }
      })

      this.$store.commit("loader/showProcessingModal", {
        isShown: false
      })

      if (err) {
        this.$toast.error(this.$t("nfts.error.mergeError"))
      }

      console.log("err", err)
      console.log("responce", responce)
      if (err === null) {
        await this.$router.push({name: "nfts"})
      }
    },
    async lvlUpJustNFTBySFC(cur) {
      const [err, responce] = await this.levelUpJustNFTBySFC({
        tokenId: this.nft.tokenId,
        sfcAmount: cur === 'SFC' ? this.upgradeBySFCForm.sfcAmount : 0,
        sfc2Amount: cur === 'SFCNEW' ? this.upgradeBySFCForm.sfc2Amount : 0,
        onAllowancePending: () => {
          this.$store.commit("loader/showProcessingModal", {
            isShown: true,
            text: this.$t("matrix.activationModal.allowancePending")
          })
        },
        onBlockchainPending: () => {
          this.$store.commit("loader/showProcessingModal", {
            isShown: true,
            text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                                ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
          })
        }
      })

      this.$store.commit("loader/showProcessingModal", {
        isShown: false
      })

      if (err) {
        this.$toast.error(this.$t("nfts.error.upgradeError"))
      }

      console.log("err", err)
      console.log("responce", responce)

      setTimeout(() => {
        this.refreshMetadata();
      }, 3000);
    },
    getMergeNftById(tokenId) {
      return this.nftList.find(x => x.tokenId === tokenId)
    },

    async upgradeNftVersion() {
      try {
        await this.migrateRoyaltyNFT({
          level: this.getNftLvl(this.contractAddress),
          tokenId: this.tokenId,
          onBlockchainPending: () => {
            this.$store.commit("loader/showProcessingModal", {
              isShown: true,
              text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                                ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
            })
          }
        })

        await this.$router.push({name: "nfts"})
      } catch (e) {
          this.$toast.error(this.$t("nfts.error.mergeError"))
      } finally {
        this.$store.commit("loader/showProcessingModal", {
          isShown: false
        })
      }
    },
    refreshMetadata() {
      this.isRefreshingMetadata = true
      nftsApi
        .refreshMetadata(this.$route.params.contractAddress, this.$route.params.tokenId)
        .then(() => {
        })
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => {
          this.isRefreshingMetadata = false
        })
    },
  },
  beforeDestroy() {
    for (const id of this.intervalTimers) {
      clearInterval(id)
    }
    this.intervalTimers = []
  }
}
</script>

<style scoped lang="scss">
.canvasjs-chart-credit {
  display: none;
}

.Nft {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    outline: none;
  }

  body {
    font-family: "Poppins", sans-serif;
    background: #111111;
  }

  .header-back {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .header-back-title {
      margin-left: 10px;
    }

    &:hover,
    &:focus {
      color: var(--color-yellow);
    }
  }

  .title {
    padding: 24px;
    background: rgba(255, 255, 255, 0.03);
    font-size: 22px;
    line-height: 30px;
    max-height: 120px;
    font-weight: 500;
    color: #e6e6e6;
    border-radius: 8px 8px 0 0;

    @media (max-width: 992px) {
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: 560px) {
      padding: 22px 16px;
    }
  }

  .tabs {
    display: flex;
    margin: 22px 0 -24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;

    li {
      a {
        padding-bottom: 15px;
        color: #666666;
        cursor: pointer;
      }

      &:hover,
      &:focus,
      &.active {
        a {
          color: #e6e6e6;
          //border-bottom: 2px solid #ff9501;
          border-bottom: 2px solid var(--color-yellow);
        }
      }
    }

    li + li {
      margin-left: 16px;
    }
  }

    .main.column-right {
        width: 100%;
        display: grid;
        // grid-template-columns: 1fr 2fr .7fr;
        grid-template-columns: 1fr 2fr;
        grid-gap: 8px;

    .main__nft {
      display: flex;
      flex-direction: column;
      min-width: 320px;
      max-width: 688px;
      //height: 96vh;
      //background: linear-gradient(180deg, #191919 7.23%, #191919 31.8%, #1b1b1b 51.74%, #1b1b1b 94.58%);
      background-color: var(--color-black-800);
      border-radius: 10px;
      overflow: hidden;
      //@media (max-height: 800px) {
      //  height: 96vh;
      //}
      @media (max-width: 400px) {
        min-width: 100%;
      }

      &-title {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 24px 24px 20px;
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.01em;
        color: rgba(255, 255, 255, 0.4);
        background: rgba(255, 255, 255, 0.03);
        backdrop-filter: blur(20px);

        .name {
          display: flex;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            color: #ffffff;

            &::after {
              width: 4px;
              height: 4px;
              margin: 0 8px;
              background: #666666;
              border-radius: 50%;
              content: "";
            }
          }
        }

        .exp {
          margin-top: 24px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #e6e6e6;

          @media (max-width: 768px) {
            margin-top: 0;
          }
        }

        .progressbar {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          //background: linear-gradient(180deg, rgba(255, 149, 1, 0) 0%, rgba(255, 149, 1, 0.2) 100%);
          background: linear-gradient(rgba(254, 221, 0, 0) 0%, rgba(254, 221, 0, 0.2) 100%);
          //border-bottom: 4px solid #ff9501;
          border-bottom: 4px solid var(--color-yellow);
        }

        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 26px;
        }

        @media (max-width: 768px) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 20px 22px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }

      &-item-container {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        //padding: 7% 0;

        .upgrateVersion {
          widows: 100%;
          padding: 20px;
          //padding: 0 7%;
          //margin-bottom: 6%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            color: #ffffff;
            padding: 5px 15px;
            border-radius: 5px;
            transition: .3s;
            background-color: var(--color-orange-900);

            &:hover {
              background-color: var(--color-orange-800);
            }
          }
        }

        @keyframes rotating {
          from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }

          to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        .refresh-metadata {
            position: absolute;
            z-index: 20;
            top: 80px;
            // top: 80px;
            right: 25px;
            cursor: pointer;
            display: flex;
            align-items: center;

            .SvgVue {
                fill: #fff;
                margin-right: 5px;

                &.spin {
                    -webkit-animation: rotating 2s linear infinite;
                    -moz-animation: rotating 2s linear infinite;
                    -ms-animation: rotating 2s linearr infinite;
                    -o-animation: rotating 2s linear infinite;
                    animation: rotating 2s linear infinite;
                }
            }

            &:hover {
                .SvgVue {
                    fill: var(--color-yellow);

                }
            }
        }
      }

      &-item {
        //max-width: 240px;
        //margin: auto;
        position: relative;
        display: flex;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 688px;
        }

        &_address {
          font-weight: 400;
          font-size: 8px;
          line-height: 10px;
          letter-spacing: 0.2em;
          color: #02d467;
          transform: rotate(-90deg);
          position: absolute;
          right: -31px;
          top: 34%;

          @media (max-width: 768px) {
            right: 13%;
            font-size: 4.47704px;
            line-height: 5px;
            top: 38%;
          }
        }

        //@media (max-width: 1250px) {
        //  padding: 50px 0;
        //}

        //@media (max-width: 768px) {
        //  padding: 50px;
        //}
      }

      @media (max-width: 768px) {
        overflow: unset;
      }
    }

    .main__content {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .nft__upgrade {
        width: 100%;
        //background: #1a1a1a;
        background-color: var(--color-black-800);
        border-radius: 8px;
        overflow: hidden;

        .upgrade__merge {
          display: flex;
          align-items: center;
          padding: 24px;

          .merge__add {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 8px;
            @media (max-width: 600px) {
              grid-template-columns: 1fr 1fr;
            }
            @media (max-width: 440px) {
              grid-template-columns: 1fr;
            }

            .btn-add {
              width: 128px;
              height: 128px;
              //padding: 10px 15px;
              background: transparent;
              border: 1px dashed rgba(255, 255, 255, 0.1);
              border-radius: 8px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;


              &:hover,
              &:focus {
                //border: 1px solid var(--color-mustard);
                border: 1px solid var(--color-yellow);
              }

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                white-space: nowrap;
                gap: 3px;
                padding: 8px 10px;
                background: rgba(255, 255, 255, 0.1);
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                color: #ffffff;
                border-radius: 4px;

                img {
                  object-fit: contain;
                  width: 18px;
                  height: 18px;
                }
              }

              .btn-add-image {
                //width: 100%;
                height: 100%;
                border-radius: 8px;
                //object-fit: contain;
              }

              @media (max-width: 992px) {
                width: 160px;
                height: 160px;
              }
            }
          }

          .merge__submit {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 160px;
            justify-content: space-between;
            margin-left: auto;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            color: #666666;

            .btn-upgrade {
              width: 100%;
              margin-top: 16px;
              padding: 14px 0;
              background: rgba(255, 255, 255, 0.1);
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #ffffff;
              border-radius: 8px;
              cursor: pointer;
              border: 2px solid hsla(0, 0%, 0%, 0%);

              &:hover,
              &:focus {
                //border: 2px solid var(--color-mustard);
                border: 2px solid var(--color-yellow);
              }

              &.disabled {
                background: rgba(255, 255, 255, 0.03);
                color: rgba(255, 255, 255, 0.2);
                cursor: default;
                pointer-events: none;
              }
            }

            @media (max-width: 992px) {
              max-width: 100%;
              margin: 24px auto 0;
            }
          }

          @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
          }
        }

        .upgrade__currency {
          padding: 8px 24px;

          &-item {
            display: flex;
            align-items: center;
            padding: 16px 0;

            .token {
              display: grid;
              grid-template-columns: 48px auto;
              grid-gap: 16px;
              align-items: center;

              &__img {
                width: 48px;
                height: 48px;
                background: rgba(255, 255, 255, 0.03);
                border-radius: 12px;
              }

              &__name {
                display: flex;
                flex-direction: column;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #e6e6e6;

                p {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #666666;

                  @media (max-width: 560px) {
                    span {
                      color: #ffffff;
                    }
                  }
                }

                @media (max-width: 560px) {
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                }
              }

              @media (max-width: 560px) {
                width: 100%;
              }
            }

            .amount {
              width: 108px;
              height: 40px;
              margin-left: auto;
              margin-right: 8px;
              padding: 10px 16px;
              background: transparent;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: rgba(255, 255, 255, 0.3);
              border: 1px solid rgba(255, 255, 255, 0.1);
              border-radius: 8px;

              &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.3);
              }

              &:hover,
              &:focus {
                color: var(--color-white);
                border: 1px solid var(--color-yellow);
              }

              @media (max-width: 560px) {
                width: 100%;
                margin: 16px 0 8px;
              }
            }

            .btn-upgrade {
              width: 108px;
              height: 40px;
              background: rgba(255, 255, 255, 0.1);
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #ffffff;
              border-radius: 8px;
              border: 2px solid hsla(0, 0%, 0%, 0%);

              &:hover,
              &:focus {
                //border: 2px solid var(--color-mustard);
                border: 2px solid var(--color-yellow);
              }

              &.disabled {
                background: rgba(255, 255, 255, 0.03);
                color: rgba(255, 255, 255, 0.2);
                cursor: default;
                pointer-events: none;
              }

              @media (max-width: 560px) {
                width: 100%;
              }
            }

            .btn-soon {
              width: 224px;
              height: 40px;
              margin-left: auto;
              background: rgba(255, 255, 255, 0.03);
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: rgba(255, 255, 255, 0.2);
              border-radius: 8px;
              border: none;

              @media (max-width: 560px) {
                width: 100%;
                margin-top: 16px;
              }
            }

            & + .upgrade__currency-item {
              border-top: 1px solid #333333;
            }

                        @media (max-width: 560px) {
                            flex-direction: column;
                        }
                    }
                }
            }
            .nft__statistic {
                width: 100%;
                //background: #1a1a1a;
                background-color: var(--color-black-800);
                border-radius: 8px;
                overflow: hidden;
                //height: 63vh;
                @media (max-height: 800px) {
                    height: 54.4vh;
                }

                &-chart {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 354px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(255, 255, 255, 0.4);

                    span {
                        margin-bottom: 4px;
                        padding: 0 16px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                    }
                }
            }
            .statistics {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                background-color: var(--color-black-800);
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                .title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .sort__wrapper {
                    display: flex;
                    align-items: center;
    
                    .sort {
                        display: flex;
                        justify-content: space-around;
                        max-width: 160px;
                        margin-left: auto;
                        padding: 2px;
                        background: var(--color-white-10);
                        border-radius: var(--indent-8);
                        font-weight: var(--font-500);
                        font-size: 12px;
    
                        
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0 13px;
                            cursor: pointer;
                            &.active {
                                background: var(--color-white-20);
                                border-radius: 6px;
                            }
                        }
    
                        @media (max-width: 768px) {
                            width: calc(100% - 32px);
                            max-width: 100%;
                            margin: 0 auto;
                            position: absolute;
                            bottom: var(--indent-16);
                            left: 0;
                            right: 0;
                        }
                    }
    
                    @media (max-width: 768px) {
                        margin: 0 0 -8px;
                    }
                }
    
                .tab {
                    position: relative;
                    background: linear-gradient(to top, rgba(0,0,0,.6) 2%, #1a1a1a 40%);
                    width: 100%;
                    height: 43vh;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    overflow: hidden;
                    @media (min-height: 850px) {
                        height: 53.8vh;   
                    }
                    @media (max-width: 768px) {
                        height: 56vh;   
                    }
                    .chartDeleteText {
                        position: absolute;
                        left: 0;
                        bottom: -12px;
                        width: 100%;
                    }
                    .totals {
                        display: flex;
                        // border-bottom: var(--border-black-700);
    
                        &__item {
                            flex-basis: 50%;
                            padding: var(--indent-22);
    
                            &__title {
                                color: var(--color-black-500);
                                font-size: 14px;
                                margin-bottom: 8px;
                                @media (max-width: 768px) {
                                    font-size: (--font-12);
                                }
                            }
    
                            .totals__sum {
                                display: flex;
                                align-items: center;
                                p {
                                    font-size: var(--font-28);
                                    span {
                                        font-weight: var(--font-200);
                                        color: var(--color-black-500);
                                    }
                                }
                                .earning {
                                    &::before{
                                        content:"• ";
                                        color: #F8956C;
                                    }
                                }
                                .spending {
                                    &::before{
                                        content:"• ";
                                        color: #C68DFF;
                                    }
                                }
                                .totals__percentage {
                                    span {
                                        margin-right: 5px;
                                        color: var(--color-green);
                                        font-weight: var(--font-600);
                                        font-size: 14px;
                                    }
                                    margin-left: auto;
                                    display: flex;
                                }
    
                                @media (max-width: 768px) {
                                    font-size: (--font-24);
    
                                }
                            }
    
                            @media (max-width: 768px) {
                                flex-basis: 100%;
                                padding: 0;
                            }
                        }
    
                        &__item+.totals__item {
                            &:nth-child(2) {
                                border-left: var(--border-black-700);
                            }
    
                            @media (max-width: 768px) {
                                border-left: none;
                                margin-top: var(--indent-20);
                            }
                        }
    
                        @media (max-width: 768px) {
                            flex-wrap: wrap;
                            padding: var(--indent-20) var(--indent-16);
                            border-bottom: none;
                        }
                    }
    
                    &#activity {
                        .totals__percentage {
                            margin-top: var(--indent-12);
                        }
                    }
                }
            }

      @media (max-width: 768px) {
        .main__content {
          position: relative;
          padding-bottom: 58px;
        }
      }
    }

    @media (max-width: 1250px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 3px;
    }
  }

  .subtitle {
    margin: -24px 0 24px;
  }

  .modal__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    align-items: start;
    height: 100%;
    padding: 16px;
    overflow: auto;

    &-item {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 10px;

      label {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 40px 55px 71px;
        transition: 0.3s ease;
        cursor: pointer;

        img {
          max-width: 100%;
        }

        p {
          position: absolute;
          width: 100%;
          bottom: 18px;
          left: 0;
          margin-top: 37px;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          text-align: center;
          color: rgba(255, 255, 255, 0.3);
        }

        .checked__number {
          position: absolute;
          width: 20px;
          height: 20px;
          right: 14px;
          top: 14px;
          background: #ff9501;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #1a1a1a;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 10px;
        }
      }

      input[type="checkbox"]:checked + label {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
      }
    }

    &-count {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      min-height: 48px;
      left: 50%;
      bottom: 24px;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.4);
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #ffffff;
      backdrop-filter: blur(20px);
      border-radius: 12px;
      cursor: default;

      p {
        width: 149px;
      }
    }

    &-merge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 138px;
      height: 48px;
      margin: 4px;
      background: #ff9501;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      border-radius: 8px;
      border: none;
      cursor: pointer;

      &.disabled {
        background: rgba(255, 255, 255, 0.03);
        color: rgba(255, 255, 255, 0.2);
        cursor: default;
        pointer-events: none;
      }
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 560px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .modal__body_empty {
    padding: 50px;
    text-transform: uppercase;
    font-size: 24px;
  }
}</style>